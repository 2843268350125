<template>
    <v-container>

        <v-row>

            <v-col cols="12">

                <h1>
                    GoSolarQuotes Privacy Policy
                </h1>

                <br>

                <p>
                    We appreciate you taking the time to connect with our providers using our services. Because we believe
                    in full transparency, here’s some information on the data we collect, how that data is used, and how it
                    is shared.
                </p>

                <h2>
                    Here’s what we collect
                </h2>

                <p>
                    In order to assist our customers, we must collect information that identifies them. That includes, but
                    isn’t limited to, names, phone numbers, addresses, email addresses, and social security numbers.
                </p>
                <p>
                    Additionally, GoSolarQuotes.co may collect data that relates to potential co-borrowers, or anyone else
                    who is involved in the lending process. Our partners may need to collect more information. If that’s the
                    case, you’ll get a detailed request from said partner.
                </p>

                <h2>
                    Here’s how we collect it
                </h2>

                <p>
                    Much of the information we collect is provided by the consumer, but GoSolarQuotes does use other sources
                    to obtain data, including:
                <ul>
                    <li>
                        Our partners
                    </li>
                    <li>
                        Service providers
                    </li>
                    <li>
                        Credit bureaus
                    </li>
                    <li>
                        Third parties related to your inquiry
                    </li>
                    <li>
                        Lenders
                    </li>
                    <li>
                        Cookies
                    </li>
                    <li>
                        Web beacons
                    </li>
                </ul>
                </p>

                <h2>
                    Here’s what the collected information is used for
                </h2>
                <p>
                    Your privacy is important to us. That’s why we only use the information we’ve collected for the intents
                    and purposes of the services that we provide.
                </p>
                <p>
                    Your collected information also helps us make changes and improvements to enhance user experience, and
                    may be used to personalize your own experience while on our site.
                </p>
                <p>
                    Additionally, we may use the data we’ve collected to comply with applicable laws, respond to any legal
                    requests that may arise, for business management purposes, or to prevent fraud and/or misuse.
                </p>


                <h2>
                    What about information sharing?
                </h2>
                <p>
                    To be clear, <strong>Go Refinance does not share your personal information with non-affiliated parties
                        or companies,</strong> except to provide the products or services that you yourself have requested.
                </p>

                <p>
                    We may share your information only under the following circumstances:
                <ul>
                    <li>
                        In connection with state or federal licensing requirements.
                    </li>
                    <li>
                        In connection with a substantial corporate transaction involving GoSolarQuotes such as the sale of
                        GoSolarQuotes, a divestiture, merger, consolidation, asset sale, or bankruptcy.
                    </li>
                    <li>
                        If we feel disclosure is appropriate in order to protect our business in response to any
                        investigation, suspected legal activity, or other wrongdoing. We have the right to protect our
                        business and to enforce all agreements.
                    </li>
                    <li>
                        As a direct response to a subpoena, any similar investigative request, court order, request for
                        cooperation from a law enforcement agency, self-regulatory body, or other governmental agency. This
                        is for us to be able to utilize our legal rights and defend ourselves against any legal claims as we
                        believe is necessary by the law. In specific cases we might raise or waive any legal objection or
                        right available to us.
                    </li>
                    <li>
                        To process and fulfill your request. All our partners in our provider network, which include banks,
                        mortgage lenders, and brokers, have entered into marketing agreements with GoSolarQuotes.co and are
                        required to comply with federal and state privacy regulations. We may also receive information back
                        from our lending partners that pertain to your loan or the products and services we have mutually
                        provided to you. This information has the right to be retained for any litigation, regulatory
                        requests, law enforcement requests, internal analytics, marketing, or any other legal matter.
                    </li>
                    <li>
                        In an anonymous format. Any shared data will have been generated in a way that does not disclose
                        anything personal and in a way that is in compliance with the law. This data would be sent to any
                        third parties requesting such.
                    </li>
                </ul>
                </p>

                <h2>
                    Changes to this product privacy statement
                </h2>
                <p>
                    GoSolarQuotes reserves the right to make changes to this statement as we see fit. Those changes will
                    comply with all other current GoSolarQuotes policies and agreements.
                </p>

                <h2>
                    Minors
                </h2>
                <p>
                    You must be at least 18 years old to use GoSolarQuotes.co. We do not knowingly collect, use or disclose
                    personal information about users who are under 18 years of age.
                </p>
                <p>
                    If you are a minor, you can use the services offered on GoSolarQuotes.co only in conjunction with a
                    legal guardian. For more information, please see our <a @click="openTermsAndConditions">Terms of
                        Use</a>.
                </p>

                <h2>
                    Outside of the United States
                </h2>
                <p>
                    GoSolarQuotes.co is based in the United States and is designed for use by those in the United States.
                    Therefore, it is governed solely by the laws of the United States.
                </p>

                <h2>
                    Questions? Comments? Contact us
                </h2>
                <p>
                    If you have any questions, concerns, or comments about this product privacy notice, please reach out to
                    us at <a href="mailto:nick@quotelogic.co">contact@gosolarquotes.co</a>.
                </p>

            </v-col>

        </v-row>

    </v-container>
</template>

<script>

export default {
    name: 'Privacy',

    methods: {
        openTermsAndConditions() {
            this.$emit('openTermsAndConditions')
        }
    }

}
</script>

<style></style>