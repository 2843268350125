<template>
    <v-container fluid class="py-0 mt-0">
        <v-row class="header py-0 mt-0 mb-1">
            <v-col cols="1" class="py-0"></v-col>
            <v-col cols="10" class="py-0">
                <div class="maxWebsiteWidth">
                    <div class="containerheader">
                        <a href="/">
                            <img src="/img/logo.svg" alt="GoSolarQuotes.co" class="logo">
                        </a>
                        <div class="secure"><img :src="require('../assets/img/lock.svg')" alt="">
                            <span>Secure Form</span>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col cols="1" class="py-0"></v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'RefinanceHeader',


}
</script>

<style></style>