<template>
    <v-container>
        <v-row>
            <v-col cols="12">

                <h1>
                    Terms and Conditions
                </h1>

                <br>

                <h2>
                    Agreement Between User and GoSolarQuotes
                </h2>

                <p>
                    Welcome to GoSolarQuotes. The GoSolarQuotes.co website (the “Site”) consists of various web pages
                    operated by GoSolarQuotes. (“GoSolarQuotes”). This agreement applies to all of GoSolarQuotes.co and any
                    of its affiliates and subsidiaries in which it is posted. Additionally, the agreement is applied to the
                    use of any services offered through our websites. GoSolarQuotes is offered to you conditioned on your
                    acceptance without modification of the terms, conditions, and notices contained herein (the "Terms").
                    Your use of GoSolarQuotes constitutes your agreement to all such terms. Please read these terms
                    carefully, and keep a copy of them for your reference.
                </p>
                <p>
                    GoSolarQuotes.co is a loan financing and refinancing site. We believe shopping for the best loan should
                    be simple and rewarding. We provide homeowners, would-be homeowners, and ordinary people easy yet
                    powerful online tools to help them save money and time on their next loan product.
                </p>

                <h2>
                    Privacy
                </h2>
                <p>
                    Your use of the Site is subject to the <a @click="openPrivacyPolicy">privacy policy</a>. Please review
                    our privacy policy to inform yourself about our data collection and sharing practices.
                </p>

                <h2>
                    Electronic Communications
                </h2>
                <p>
                    Visiting GoSolarQuotes or sending emails to GoSolarQuotes constitutes electronic communications. You
                    consent to receive electronic communications and you agree that all agreements, notices, disclosures,
                    and other communications that we provide to you electronically, via email, and on the Site satisfy any
                    legal requirement that such communications be in writing.
                </p>

                <h2>
                    Links to Third Party Sites/Third Party Services
                </h2>
                <p>
                    GoSolarQuotes may contain links to other websites (“linked sites”). The linked sites are not under the
                    control of GoSolarQuotes, nor is GoSolarQuotes responsible for the contents of any linked site,
                    including without limitation any link contained in a linked site, or any changes or updates to a linked
                    site. GoSolarQuotes is providing these links to you only as a convenience, and the inclusion of any link
                    does not imply endorsement by GoSolarQuotes of the site or any association with its operators.
                </p>

                <p>
                    Certain services made available via GoSolarQuotes are delivered by third party sites and organizations.
                    By using any product, service, or functionality originating from the GoSolarQuotes domain, you hereby
                    acknowledge and consent that GoSolarQuotes may share such information and data with any third party with
                    whom GoSolarQuotes has a contractual relationship to provide the requested product, service, or
                    functionality on behalf of GoSolarQuotes users and customers.
                </p>

                <h2>
                    No Unlawful or Prohibited Use/Intellectual Property
                </h2>
                <p>
                    You are granted a nonexclusive, nontransferable, revocable license to access and use GoSolarQuotes
                    strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to
                    GoSolarQuotes that you will not use the Site for any purpose that is unlawful or prohibited by these
                    Terms. You may not use the Site in any manner that could damage, disable, overburden, or impair the Site
                    or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to
                    obtain any materials or information through any means not intentionally made available or provided for
                    through the Site.
                </p>
                <p>
                    All content included as part of the service—such as text, graphics, logos, and images, as well as the
                    compilation thereof—and any software used on the Site are the property of GoSolarQuotes or its suppliers
                    and protected by copyright and other laws that protect intellectual property and proprietary rights. You
                    agree to observe and abide by all copyright and other proprietary notices, legends, or other
                    restrictions contained in any such content and will not make any changes thereto.
                </p>
                <p>
                    You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create
                    derivative works, or in any way exploit any of the content, in whole or in part, found on the Site.
                    GoSolarQuotes content is not for resale. Your use of the Site does not entitle you to make any
                    unauthorized use of any protected content, and in particular you will not delete or alter any
                    proprietary rights or attribution notices in any content. You will use protected content solely for your
                    personal use, and will make no other use of the content without the express written permission of
                    GoSolarQuotes and the copyright owner. You agree that you do not acquire any ownership rights in any
                    protected content. We do not grant you any licenses, express or implied, to the intellectual property of
                    GoSolarQuotes or our licensors except as expressly authorized by these Terms.
                </p>

                <h2>
                    International Users
                </h2>
                <p>
                    The Site is controlled, operated, and administered by GoSolarQuotes from our offices within the USA. If
                    you access the Site from a location outside the USA, you are responsible for compliance with all local
                    laws. You agree that you will not use the GoSolarQuotes content accessed through GoSolarQuotes in any
                    country or in any manner prohibited by any applicable laws, restrictions, or regulations.
                </p>

                <h2>
                    No Minor Use
                </h2>
                <p>
                    The Site is applicable to and should only be used by persons 18 years of age or older. Access and use of
                    this website by persons under the age of 18 is prohibited except in conjunction with a parent or
                    guardian.
                </p>

                <h2>
                    Indemnification
                </h2>
                <p>
                    You agree to indemnify, defend, and hold harmless GoSolarQuotes, its officers, directors, employees,
                    agents, and third parties for any losses, costs, liabilities, and expenses (including reasonable
                    attorney’s fees) relating to or arising out of your use of or inability to use the Site or services, any
                    user postings made by you, your violation of any terms of this agreement, or your violation of any
                    rights of a third party, or your violation of any applicable laws, rules, or regulations. GoSolarQuotes
                    reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise
                    subject to indemnification by you, in which event you will fully cooperate with GoSolarQuotes in
                    asserting any available defenses.
                </p>

                <h2>
                    Arbitration
                </h2>
                <p>
                    In the event the parties are not able to resolve any dispute between them arising out of or concerning
                    these Terms, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for
                    damages or any other relief, then such dispute shall be resolved only by final and binding arbitration
                    pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by
                    the American Arbitration Association or a similar arbitration service selected by the parties, in a
                    location mutually agreed upon by the parties. The arbitrator's award shall be final, and judgment may be
                    entered upon it in any court having jurisdiction. In the event that any legal or equitable action,
                    proceeding, or arbitration arises out of or concerns these Terms, the prevailing party shall be entitled
                    to recover its costs and reasonable attorney’s fees. The parties agree to arbitrate all disputes and
                    claims in regards to these Terms or any disputes arising as a result of these Terms, whether directly or
                    indirectly, including tort claims that are a result of these Terms. The parties agree that the Federal
                    Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute,
                    including the scope and enforceability of this arbitration provision, shall be determined by the
                    arbitrator. This arbitration provision shall survive the termination of these Terms.
                </p>

                <h2>
                    Class Action Waiver
                </h2>
                <p>
                    Any arbitration under these Terms will take place on an individual basis; class arbitrations and
                    class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING
                    CLAIMS AGAINST THE OTHER ONLY IN EACH INDIVIDUAL'S CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
                    ANY PUTATIVE CLASS, COLLECTIVE AND/OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE
                    ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both you and GoSolarQuotes agree otherwise,
                    the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any
                    form of a representative or class proceeding.
                </p>

                <h2>
                    Liability Disclaimer
                </h2>
                <p>
                    THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE
                    INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN.
                    GOSOLARQUOTES. AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
                </p>
                <p>
                    GOSOLARQUOTES. AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY,
                    AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES, AND RELATED
                    GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL
                    SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES, AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT
                    WARRANTY OR CONDITION OF ANY KIND. GOSOLARQUOTES. AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES
                    AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES, AND RELATED GRAPHICS,
                    INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                    TITLE, AND NON-INFRINGEMENT.
                </p>
                <p>
                    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL GOSOLARQUOTES. AND/OR ITS SUPPLIERS
                    BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES, OR ANY DAMAGES
                    WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA, OR PROFITS ARISING OUT OF OR
                    IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE
                    SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION,
                    SOFTWARE, PRODUCTS, SERVICES, AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT
                    OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE,
                    EVEN IF GOSOLARQUOTES. OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE
                    SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
                    INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION
                    OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING
                    THE SITE.
                </p>

                <h2>
                    Termination/Access Restriction
                </h2>
                <p>
                    GoSolarQuotes reserves the right, in its sole discretion, to terminate your access to the Site and the
                    related services or any portion thereof at any time, without notice. To the maximum extent permitted by
                    law, this agreement is governed by the laws of the State of California and you hereby consent to the
                    exclusive jurisdiction and venue of courts in California in all disputes arising out of or relating to
                    the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to
                    all provisions of these Terms, including, without limitation, this section.
                </p>
                <p>
                    You agree that no joint venture, partnership, employment, or agency relationship exists between you and
                    GoSolarQuotes as a result of this agreement or use of the Site. GoSolarQuotes's performance of this
                    agreement is subject to existing laws and legal process, and nothing contained in this agreement is in
                    derogation of GoSolarQuotes's right to comply with governmental, court, and law enforcement requests or
                requirements relating to your use of the Site or information provided to or gathered by GoSolarQuotes
                with respect to such use. If any part of this agreement is determined to be invalid or unenforceable
                pursuant to applicable law, including, but not limited to, the warranty disclaimers and liability
                limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a
                valid, enforceable provision that most closely matches the intent of the original provision and the
                remainder of the agreement shall continue in effect.
            </p>
            <p>
                Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and
                GoSolarQuotes with respect to the Site, and it supersedes all prior or contemporaneous communications
                and proposals, whether electronic, oral, or written, between the user and GoSolarQuotes with respect to
                the Site. A printed version of this agreement and of any notice given in electronic form shall be
                admissible in judicial or administrative proceedings based upon or relating to this agreement to the
                same extent and subject to the same conditions as other business documents and records originally
                generated and maintained in printed form. It is the express wish to the parties that this agreement and
                all related documents be written in English.
            </p>

            <h2>
                Terms Applicable to All Services
            </h2>
            <p>
                If you fill out our form on GoSolarQuotes's website, you are giving your permission to be contacted by
                providers for each request submitted by you. This also gives your consent to receive phone calls at the
                number you provided even if you are listed on any Do Not Call list at any level, ranging from federal,
                state, local, internal, or corporate. In addition, you consent to subscribing to our mailing list and
                receiving emails from providers. All consent remains valid and legal until you request to opt out or
                unsubscribe from receiving further messages.
            </p>
            <p>
                To opt out of receiving phone calls, write to <a
                    href="mailto:nick@quotelogic.co">contact@gosolarquotes.co</a>. GoSolarQuotes is not responsible for
                a provider’s failure to comply with our instructions to stop contacting you
            </p>

            <h2>
                Financial Education Content
            </h2>
            <p>
                The Site provides tools and information to allow you to further your financial education and learn how
                certain financial decisions could impact your financial wellbeing. We provide these materials for
                informational, educational, and entertainment purposes only, and at no time are we providing legal,
                financial, investment, tax planning, or medical advice. You may see offers on the Site from companies
                that compensate us. This compensation may influence where the Site shows the offers and how often. The
                Site does not include the entire universe of available loan products.
            </p>
            <p>
                GoSolarQuotes is NOT a lender or creditor.

            <ul>
                <li>
                    We are not an agent to you or any loan provider.
                </li>
                <li>
                    We ONLY provide admin services, and as a user it is your responsibility to research and make
                    decisions independently based on what suits your needs.
                </li>
                <li>
                    Any web form you submit is not an application for credit and is to be interpreted as an inquiry to
                    providers you can be matched with.
                </li>
                <li>
                    It is the responsibility of providers to provide these services to you, and in no way should
                    GoSolarQuotes be held accountable for any damages or costs related to your interactions with a
                    provider.
                </li>
                <li>
                    Providers have the right to keep your information that was submitted on the web form.
                </li>
            </ul>
            </p>

            <h2>
                Social Media Content
            </h2>
            <p>
                For any content that you submit on the Site or on GoSolarQuotes's Facebook, Twitter, or other social
                media property, you grant GoSolarQuotes a perpetual, irrevocable, royalty-free, transferable right and
                license to use, copy, modify, delete in its entirety, adapt, publish, translate, create derivative works
                from, and/or sell and/or distribute such content and/or incorporate such content into any form, medium,
                or technology throughout the world without compensation to you.
            </p>

            <h2>
                How We Are Compensated
            </h2>
            <p>
                GoSolarQuotes is paid a marketing lead generation fee by providers for the goods, facilities, and
                services provided. Your use of the Site and/or GoSolarQuotes's services constitutes your agreement with
                this compensation arrangement.
            </p>

            <h2>
                Contact Us
            </h2>
            <p>
                GoSolarQuotes welcomes your questions or comments regarding the Terms:
            </p>
            <p>
                GoSolarQuotes.
            </p>
            <p>
                Email Address:
                <br>
                <a href="mailto:nick@quotelogic.co">contact@gosolarquotes.co</a>
            </p>
            <p>
                Effective as of May 1, 2021
            </p>

        </v-col>
    </v-row>
</v-container></template>

<script>
export default {
    name: 'TermsAndConditions',

    methods: {
        openPrivacyPolicy() {
            this.$emit('openPrivacyPolicy')
        }
    }

}
</script>

<style></style>